import React from 'react';

const InlineIcon = ({src, alt}) => {
  return (
    <span className="inline-icon" style={{height:"1em"}}>
      <img src={src} alt=""/>
    </span>
  );
};

export default InlineIcon;