import React from 'react';
import BlockContent from '@sanity/block-content-to-react'
import InlineIcon from './icon/InlineIcon'
// const { apiPwd } = require('../../../back/sanity.json')
const {
  api: { projectId, dataset },
} = require("../../../back/sanity.json")

const serializers = {
  types: {
    block: (props) => {
      const {style = 'normal'} = props.node;
      if (style === 'chapo') {
        return (
          <p className="chapo fM">
            {props.children}
          </p>
        )
      }
      if (style === 'chapo_bender') {
        return (
          <p className="chapo fSeg fM">
            {props.children}
          </p>
        )
      }
      return BlockContent.defaultSerializers.types.block(props)
    },
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  },
  marks: {
    strong: ({children}) => (
      <span className="strong">
        {children}
      </span>
    ),
    orange: ({children}) => (
      <span className="orange">
        {children}
      </span>
    ),
    align_left: ({children}) => (
      <span className="tal db">
        {children}
      </span>
    ),
    align_center: ({children}) => (
      <span className="tac db">
        {children}
      </span>
    ),
    align_right: ({children}) => (
      <span className="tar db">
        {children}
      </span>
    ),
    inlineicon (props) {
      switch (props.mark._type){
        case 'inlineicon':
          if(props.mark.asset) { return <InlineIcon src={props.mark.asset.url || ''} alt={props.children[0]}/> } else { return null }
      }
    },
    link: ({mark, children}) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark
      return blank 
        ? <a href={href} target="_blank" rel="noopener">{children}</a>
        : <a href={href}>{children}</a>
    }
  }
}


const Texte = ({ texte }) => {
  console.log(texte)
  return (
    <div className="texte">
      <BlockContent 
      blocks={texte} 
      serializers={serializers} 
      projectId={projectId}
      dataset={dataset}
      />
    </div>
  );
};

export default Texte;